import React from 'react';
import { NavLink } from "react-router-dom";

const Header = () => {
    return (
        <header className="header sticky-bar">
            <div className="container">
            <div className="main-header">
                <div className="header-left">
                <div className="header-logo"><a className="d-flex" href="index.html"><img alt="Agon" src="assets/imgs/template/logo.svg" /></a></div>
                <div className="header-nav">
                    <nav className="nav-main-menu d-none d-xl-block">
                    <ul className="main-menu">
                        <li className="has-children"><a className="active" href="#">Home</a>
                        <ul className="sub-menu two-col">
                            <NavLink to="/services">
                            <li><i className="fi fi-rr-home"></i>Homepage - 1</li>
                            </NavLink>
                            <li><a href="index-2.html"><i className="fi fi-rr-home"></i>Homepage - 2</a></li>
                            <li><a href="index-3.html"><i className="fi fi-rr-home"></i>Homepage - 3</a></li>
                            <li><a href="index-4.html"><i className="fi fi-rr-home"></i>Homepage - 4</a></li>
                            <li><a href="index-5.html"><i className="fi fi-rr-home"></i>Homepage - 5</a></li>
                            <li><a href="index-6.html"><i className="fi fi-rr-home"></i>Homepage - 6</a></li>
                            <li><a href="index-7.html"><i className="fi fi-rr-home"></i>Homepage - 7</a></li>
                            <li><a href="index-8.html"><i className="fi fi-rr-home"></i>Homepage - 8</a></li>
                        </ul>
                        </li>
                        <li className="has-children"><a href="#">About</a>
                        <ul className="sub-menu">
                            <li><a href="page-about-1.html"><i className="fi fi-rr-star"></i>About Us - 1</a></li>
                            <li><a href="page-about-2.html"><i className="fi fi-rr-star"></i>About Us - 2</a></li>
                            <li><a href="page-about-3.html"><i className="fi fi-rr-star"></i>About Us - 3</a></li>
                        </ul>
                        </li>
                        <li className="has-children"><a href="#">Company</a>
                        <ul className="sub-menu">
                            <li><a className="closer" href="page-service-1.html"><i className="fi fi-rr-gem"></i>Services - 1</a></li>
                            <li><a className="closer" href="page-service-2.html"><i className="fi fi-rr-gem"></i>Services - 2</a></li>
                            <li className="hr"><span></span></li>
                            <li><a className="closer" href="page-pricing-1.html"><i className="fi fi-rr-database"></i>Pricing - 1</a></li>
                            <li><a className="closer" href="page-pricing-2.html"><i className="fi fi-rr-database"></i>Pricing - 2</a></li>
                            <li className="hr"><span></span></li>
                            <li><a className="closer" href="page-faqs-1.html"><i className="fi fi-rr-headset"></i>FAQs - 1</a></li>
                            <li><a className="closer" href="page-faqs-2.html"><i className="fi fi-rr-headset"></i>FAQs - 2</a></li>
                            <li className="hr"><span></span></li>
                            <li><a className="closer" href="page-career.html"><i className="fi fi-rr-briefcase"></i>Career</a></li>
                            <li><a className="closer" href="page-career-detail.html"><i className="fi fi-rr-briefcase"></i>Career Detail</a></li>
                        </ul>
                        </li>
                        <li className="has-children"><a href="#">Pages</a>
                        <ul className="sub-menu">
                            <li><a href="page-contact.html"><i className="fi fi-rr-paper-plane"></i>Contact</a></li>
                            <li><a href="page-signup.html"><i className="fi fi-rr-user-add"></i>Sign Up</a></li>
                            <li><a href="page-login.html"><i className="fi fi-rr-fingerprint"></i>Log In</a></li>
                            <li><a href="page-reset.html"><i className="fi fi-rr-settings"></i>Reset Password</a></li>
                            <li><a href="404.html"><i className="fi fi-rr-exclamation"></i>Error 404</a></li>
                        </ul>
                        </li>
                        <li className="has-children"><a href="#">Blog</a>
                        <ul className="sub-menu">
                            <li><a className="closer" href="blog-1.html"><i className="fi fi-rr-edit"></i>Blog Archive - 1</a></li>
                            <li><a className="closer" href="blog-2.html"><i className="fi fi-rr-edit"></i>Blog Archive - 2</a></li>
                            <li className="hr"><span></span></li>
                            <li><a href="blog-single.html"><i className="fi fi-rr-document-signed"></i>Blog Single</a></li>
                        </ul>
                        </li>
                        <li className="has-children"><a href="#">Shop</a>
                        <ul className="sub-menu">
                            <li><a className="closer" href="page-shop-grid-1.html"><i className="fi fi-rr-edit"></i>Shop Grid - 1</a></li>
                            <li><a className="closer" href="page-shop-grid-2.html"><i className="fi fi-rr-edit"></i>Shop Grid - 2</a></li>
                            <li><a className="closer" href="page-shop-single.html"><i className="fi fi-rr-edit"></i>Product Details</a></li>
                        </ul>
                        </li>
                    </ul>
                    </nav>
                    <div className="burger-icon burger-icon-white"><span className="burger-icon-top"></span><span className="burger-icon-mid"></span><span className="burger-icon-bottom"></span></div>
                </div>
                </div>
                <div className="header-right">
                <div className="block-signin"><a className="btn btn-default hover-up icon-arrow-right" href="page-signup.html">Get Started</a></div>
                </div>
            </div>
            </div>
        </header>
    );
};

export default Header;