import React from 'react';

const Footer = () => {
    return (
        <footer className="footer mt-50">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-4 col-sm-6 text-center text-md-start"><a href="index.html"><img alt="Agon" src="assets/imgs/template/logo.svg" /></a></div>
              <div className="col-md-8 col-sm-6 text-center text-md-end"><span className="color-gray-900 text-heading-6 mr-30 text-mb-sm-20">Ready to get started?</span><a className="btn btn-square" href="page-signup.html">Create an Account</a></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 width-20 mb-30">
              <h4 className="text-heading-5">Contact</h4>
              <div className="mt-20 text-body-text color-gray-600 mb-20">4517 Washington Ave. Manchester, Kentucky 39495</div>
              <div className="mt-20 text-body-text color-gray-600">(239) 555-0108</div>
              <div className="text-body-text color-gray-600">contact@agon.com</div>
            </div>
            <div className="col-lg-3 width-20 mb-30">
              <h4 className="text-heading-5">About Us</h4>
              <ul className="menu-footer mt-20">
                <li><a href="#">Mission &amp; Vision</a></li>
                <li><a href="#">Our Team</a></li>
                <li><a href="page-career.html">Careers</a></li>
                <li><a href="#">Press &amp; Media</a></li>
                <li><a href="#">Advertising</a></li>
                <li><a href="#">Testimonials</a></li>
              </ul>
            </div>
            <div className="col-lg-3 width-20 mb-30">
              <h4 className="text-heading-5">Discover</h4>
              <ul className="menu-footer mt-20">
                <li><a href="blog-2.html">Our Blog</a></li>
                <li><a href="page-pricing-1.html">Plans &amp; Pricing</a></li>
                <li><a href="#">Knowledge Base</a></li>
                <li><a href="#">Cookie Policy</a></li>
                <li><a href="#">Office Center</a></li>
                <li><a href="blog-1.html">News &amp; Events</a></li>
              </ul>
            </div>
            <div className="col-lg-3 width-20 mb-30">
              <h4 className="text-heading-5">Support</h4>
              <ul className="menu-footer mt-20">
                <li><a href="page-faqs-1.html">FAQs</a></li>
                <li><a href="#">Editor Help</a></li>
                <li><a href="#">Community</a></li>
                <li><a href="#">Live Chatting</a></li>
                <li><a href="page-contact.html">Contact Us</a></li>
                <li><a href="#">Support Center</a></li>
              </ul>
            </div>
            <div className="col-lg-3 width-16">
              <h4 className="text-heading-5">Useful links</h4>
              <ul className="menu-footer mt-20">
                <li><a href="#">Request an offer</a></li>
                <li><a href="#">How it works</a></li>
                <li><a href="page-pricing-2.html">Pricing</a></li>
                <li><a href="#">Reviews</a></li>
                <li><a href="#">Stories</a></li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom mt-20">
            <div className="row">
              <div className="col-md-6"><span className="color-gray-400 text-body-lead">&copy; Agon Official 2022</span><a className="text-body-text color-gray-400 ml-50" href="page-terms.html">Privacy policy</a><a className="text-body-text color-gray-400 ml-50" href="page-terms.html">Cookies</a><a className="text-body-text color-gray-400 ml-50" href="page-terms.html">Terms of service</a></div>
              <div className="col-md-6 text-center text-lg-end text-md-end">
                <div className="footer-social"><a className="icon-socials icon-facebook" href="https://facebook.com" target="_blank"></a><a className="icon-socials icon-twitter" href="https://twitter.com" target="_blank"></a><a className="icon-socials icon-instagram" href="https://www.instagram.com" target="_blank"></a><a className="icon-socials icon-linkedin" href="https://www.linkedin.com" target="_blank"></a></div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;