import React from 'react';

const MobileHeader = () => {
    return (
      <div className="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="user-account"><img src="assets/imgs/template/ava_1.png" alt="Agon" />
              <div className="content">
                <h6 className="user-name">Hi <span className="text-brand">Steven !</span></h6>
                <p className="font-xs text-muted">You have 5 new messages</p>
              </div>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-menu-wrap mobile-header-border">
                <nav>
                  <ul className="mobile-menu font-heading">
                    <li className="has-children"><a className="active" href="index.html">Home</a>
                      <ul className="sub-menu">
                        <li><a href="index.html">Homepage - 1</a></li>
                        <li><a href="index-2.html">Homepage - 2</a></li>
                        <li><a href="index-3.html">Homepage - 3</a></li>
                        <li><a href="index-4.html">Homepage - 4</a></li>
                        <li><a href="index-5.html">Homepage - 5</a></li>
                        <li><a href="index-6.html">Homepage - 6</a></li>
                        <li><a href="index-7.html">Homepage - 7</a></li>
                        <li><a href="index-8.html">Homepage - 8</a></li>
                      </ul>
                    </li>
                    <li className="has-children"><a href="page-about-1.html">About</a>
                      <ul className="sub-menu">
                        <li><a href="page-about-1.html">About Us - 1</a></li>
                        <li><a href="page-about-2.html">About Us - 2</a></li>
                        <li><a href="page-about-3.html">About Us - 3</a></li>
                      </ul>
                    </li>
                    <li className="has-children"><a href="page-service-1.html">Company</a>
                      <ul className="sub-menu">
                        <li><a className="closer" href="page-service-1.html">Services - 1</a></li>
                        <li><a className="closer" href="page-service-2.html">Services - 2</a></li>
                        <li className="hr"><span></span></li>
                        <li><a className="closer" href="page-pricing-1.html">Pricing - 1</a></li>
                        <li><a className="closer" href="page-pricing-2.html">Pricing - 2</a></li>
                        <li className="hr"><span></span></li>
                        <li><a className="closer" href="page-faqs-1.html">FAQs - 1</a></li>
                        <li><a className="closer" href="page-faqs-2.html">FAQs - 2</a></li>
                        <li className="hr"><span></span></li>
                        <li><a className="closer" href="page-career.html">Career</a></li>
                        <li><a className="closer" href="page-career-detail.html">Career Detail</a></li>
                      </ul>
                    </li>
                    <li className="has-children"><a href="#">Pages</a>
                      <ul className="sub-menu">
                        <li><a href="page-contact.html">Contact</a></li>
                        <li><a href="page-signup.html">Sign Up</a></li>
                        <li><a href="page-login.html">Log In</a></li>
                        <li><a href="page-reset.html">Reset Password</a></li>
                        <li><a href="404.html">Error 404</a></li>
                      </ul>
                    </li>
                    <li className="has-children"><a href="blog-1.html">Blog</a>
                      <ul className="sub-menu">
                        <li><a className="closer" href="blog-1.html">Blog 1</a></li>
                        <li><a className="closer" href="blog-2.html">Blog 2</a></li>
                        <li className="hr"><span></span></li>
                        <li><a href="blog-single.html">Blog Single</a></li>
                      </ul>
                    </li>
                    <li className="has-children"><a href="#">Shop</a>
                      <ul className="sub-menu">
                        <li><a className="closer" href="page-shop-grid-1.html">Shop Grid 1</a></li>
                        <li><a className="closer" href="page-shop-grid-2.html">Shop Grid 2</a></li>
                        <li><a className="closer" href="page-shop-single.html">Product Details</a></li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li><a href="#">Profile</a></li>
                  <li><a href="#">Work Preferences</a></li>
                  <li><a href="#">My Boosted Shots</a></li>
                  <li><a href="#">My Collections</a></li>
                  <li><a href="#">Account Settings</a></li>
                  <li><a href="#">Go Pro</a></li>
                  <li><a href="page-login.html">Sign Out</a></li>
                </ul>
              </div>
              <div className="site-copyright color-gray-400">Copyright 2022 &copy; Agon - Agency Template.<br />Designed by<a href="http://alithemes.com">&nbsp; AliThemes</a></div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default MobileHeader;