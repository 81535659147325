import React from 'react';
import Header from '../components/Header';
import MobileHeader from '../components/MobileHeader';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <div>
            <Header />
            <MobileHeader />
                <main className="main">
                    <section className="section-box">
                        <div className="banner-hero banner-1">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-7">
                                <h1 className="text-display-2">We are <span className="color-green-900">awesome team</span> for your business dream</h1>
                                <p className="text-body-lead-large color-gray-500 mt-40 pr-40">Integrated workflow designed for product teams. We create world-class development and branding</p>
                                <div className="mt-40"><a className="btn btn-black icon-arrow-right-white" href="page-service-1.html">Get Start</a><a className="btn btn-link icon-arrow-right color-gray-900 text-heading-6" href="page-about-1.html">Learn More</a></div>
                            </div>
                            <div className="col-lg-5 d-none d-lg-block">
                                <div className="banner-imgs">
                                <div className="video-block shape-1"><a className="popup-youtube btn-play-video" href="https://www.youtube.com/watch?v=oRI37cOPBQQ"></a><span className="text-heading-4">Watch intro video</span></div><img className="img-responsive shape-2" alt="Agon" src="assets/imgs/page/homepage1/banner.png" />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <div className="section-box overflow-visible mt-70">
                        <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center"><a className="item-logo box-hover-shadow hover-up" href="#"><img alt="Agon" src="assets/imgs/slider/logo/sample-logo-1.svg" /></a></div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center"><a className="item-logo box-hover-shadow hover-up" href="#"><img alt="Agon" src="assets/imgs/slider/logo/sample-logo-2.svg" /></a></div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center"><a className="item-logo box-hover-shadow hover-up" href="#"><img alt="Agon" src="assets/imgs/slider/logo/sample-logo-3.svg" /></a></div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center"><a className="item-logo box-hover-shadow hover-up" href="#"><img alt="Agon" src="assets/imgs/slider/logo/sample-logo-4.svg" /></a></div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center"><a className="item-logo box-hover-shadow hover-up" href="#"><img alt="Agon" src="assets/imgs/slider/logo/sample-logo-5.svg" /></a></div>
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6 text-center"><a className="item-logo box-hover-shadow hover-up" href="#"><img alt="Agon" src="assets/imgs/slider/logo/sample-logo-6.svg" /></a></div>
                        </div>
                        </div>
                    </div>
                    <section className="section-box">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-sm-1 col-12"></div>
                            <div className="col-lg-8 col-sm-10 col-12 text-center mt-70">
                            <h2 className="text-heading-1 color-gray-900">See why we are trusted<br className="d-lg-block d-none" />the world over</h2>
                            <p className="text-body-lead-large color-gray-600 mt-20">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit laborum &mdash; semper quis lectus nulla.</p>
                            </div>
                            <div className="col-lg-2 col-sm-1 col-12"></div>
                        </div>
                        </div>
                        <div className="container">
                        <div className="text-center mt-90">
                            <ul className="nav" role="tablist">
                            <li><a className="btn btn-default btn-bd-green-hover btn-select active" href="#tab-1" data-bs-toggle="tab" role="tab" aria-controls="tab-1" aria-selected="true">Branding</a></li>
                            <li><a className="btn btn-default btn-bd-green-hover btn-select" href="#tab-2" data-bs-toggle="tab" role="tab" aria-controls="tab-2" aria-selected="true">Development</a></li>
                            <li><a className="btn btn-default btn-bd-green-hover btn-select" href="#tab-3" data-bs-toggle="tab" role="tab" aria-controls="tab-3" aria-selected="true">Animation</a></li>
                            <li><a className="btn btn-default btn-bd-green-hover btn-select" href="#tab-4" data-bs-toggle="tab" role="tab" aria-controls="tab-4" aria-selected="true">User Experience</a></li>
                            <li><a className="btn btn-default btn-bd-green-hover btn-select" href="#tab-5" data-bs-toggle="tab" role="tab" aria-controls="tab-5" aria-selected="true">Social Network</a></li>
                            <li><a className="btn btn-default btn-bd-green-hover btn-select" href="#tab-6" data-bs-toggle="tab" role="tab" aria-controls="tab-6" aria-selected="true">Marketing</a></li>
                            </ul>
                        </div>
                        </div>
                        <div className="container">
                        <div className="tab-content">
                            <div className="tab-pane fade active show" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
                            <div className="bg-2 panel-box mt-50">
                                <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="box-optimized">
                                    <h3 className="text-heading-2">Optimize and scale, easy to start</h3>
                                    <p className="text-body-excerpt mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    <div className="mt-40"><a className="btn btn-default btn-white icon-arrow-right" href="page-service-1.html">Learn more</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="block-video icon-pattern"><a className="popup-youtube btn-play-video" href="https://www.youtube.com/watch?v=oRI37cOPBQQ"></a><img className="img-responsive" src="assets/imgs/page/homepage1/img-1.png" alt="Agon" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
                            <div className="bg-1 panel-box mt-50">
                                <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="box-optimized">
                                    <h3 className="text-heading-2">Design Studios That Everyone Should Know</h3>
                                    <p className="text-body-excerpt mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    <div className="mt-40"><a className="btn btn-default btn-white icon-arrow-right" href="page-service-1.html">Learn more</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="block-video icon-pattern"><a className="popup-youtube btn-play-video" href="https://www.youtube.com/watch?v=oRI37cOPBQQ"></a><img className="img-responsive" src="assets/imgs/page/homepage1/img-1-2.jpg" alt="Agon" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
                            <div className="bg-3 panel-box mt-50">
                                <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="box-optimized">
                                    <h3 className="text-heading-2">We can blend colors multiple ways</h3>
                                    <p className="text-body-excerpt mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    <div className="mt-40"><a className="btn btn-default btn-white icon-arrow-right" href="page-service-1.html">Learn more</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="block-video icon-pattern"><a className="popup-youtube btn-play-video" href="https://www.youtube.com/watch?v=oRI37cOPBQQ"></a><img className="img-responsive" src="assets/imgs/page/homepage1/img-1-3.jpg" alt="Agon" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="tab-pane fade" id="tab-4" role="tabpanel" aria-labelledby="tab-4">
                            <div className="bg-4 panel-box mt-50">
                                <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="box-optimized">
                                    <h3 className="text-heading-2">Choose The Best Plan That's For You</h3>
                                    <p className="text-body-excerpt mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    <div className="mt-40"><a className="btn btn-default btn-white icon-arrow-right" href="page-service-1.html">Learn more</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="block-video icon-pattern"><a className="popup-youtube btn-play-video" href="https://www.youtube.com/watch?v=oRI37cOPBQQ"></a><img className="img-responsive" src="assets/imgs/page/homepage1/img-1-4.jpg" alt="Agon" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="tab-pane fade" id="tab-5" role="tabpanel" aria-labelledby="tab-5">
                            <div className="bg-5 panel-box mt-50">
                                <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="box-optimized">
                                    <h3 className="text-heading-2">Subscribe our newsletter to get gift</h3>
                                    <p className="text-body-excerpt mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    <div className="mt-40"><a className="btn btn-default btn-white icon-arrow-right" href="page-service-1.html">Learn more</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="block-video icon-pattern"><a className="popup-youtube btn-play-video" href="https://www.youtube.com/watch?v=oRI37cOPBQQ"></a><img className="img-responsive" src="assets/imgs/page/homepage1/img-1-5.jpg" alt="Agon" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="tab-pane fade" id="tab-6" role="tabpanel" aria-labelledby="tab-6">
                            <div className="bg-6 panel-box mt-50">
                                <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="box-optimized">
                                    <h3 className="text-heading-2">Ready to get started? Create and Account</h3>
                                    <p className="text-body-excerpt mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    <div className="mt-40"><a className="btn btn-default btn-white icon-arrow-right" href="page-service-1.html">Learn more</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="block-video icon-pattern"><a className="popup-youtube btn-play-video" href="https://www.youtube.com/watch?v=oRI37cOPBQQ"></a><img className="img-responsive" src="assets/imgs/page/homepage1/img-1-6.jpg" alt="Agon" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <section className="section-box">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-sm-1 col-12"></div>
                            <div className="col-lg-8 col-sm-10 col-12 text-center mt-100">
                            <h2 className="text-heading-1 color-gray-900">We facilitate the creation<br className="d-lg-block d-none" />of strategy and design</h2>
                            <p className="text-body-lead-large color-gray-600 mt-20">Interactively transform magnetic growth strategies whereas prospective &quot;outside the box&quot; thinking.</p>
                            </div>
                            <div className="col-lg-2 col-sm-1 col-12"></div>
                        </div>
                        </div>
                        <div className="container mt-70">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                            <div className="card-grid-1 bg-5 bg-business hover-up">
                                <div className="grid-1-img"><img src="assets/imgs/page/homepage1/business-strategy.svg" alt="Agon" /></div>
                                <h3 className="text-heading-3 mt-20">Business Strategy</h3>
                                <p className="text-body-excerpt mt-20">You are always welcome to visit our little den. Professional in teir craft! All products were super amazing with strong attension to details, comps and overall vibe.</p>
                                <div className="mt-30"><a className="btn btn-default btn-white icon-arrow-right" href="page-about-1.html">Learn more</a></div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                            <div className="card-grid-1 bg-9 bg-local hover-up">
                                <div className="grid-1-img"><img src="assets/imgs/page/homepage1/local.svg" alt="Agon" /></div>
                                <h3 className="text-heading-3 mt-20">Local Marketing</h3>
                                <p className="text-body-excerpt mt-20">You are always welcome to visit our little den. Professional in teir craft! All products were super amazing with strong attension to details, comps and overall vibe.</p>
                                <div className="mt-30"><a className="btn btn-default btn-white icon-arrow-right" href="page-about-2.html">Learn more</a></div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                            <div className="card-grid-1 bg-2 bg-social hover-up">
                                <div className="grid-1-img"><img src="assets/imgs/page/homepage1/social.svg" alt="Agon" /></div>
                                <h3 className="text-heading-3 mt-20">Social media</h3>
                                <p className="text-body-excerpt mt-20">You are always welcome to visit our little den. Professional in teir craft! All products were super amazing with strong attension to details, comps and overall vibe.</p>
                                <div className="mt-30"><a className="btn btn-default btn-white icon-arrow-right" href="page-about-3.html">Learn more</a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <section className="section-box">
                        <div className="container mt-100">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 block-img-we-do"><img className="bdrd-16 img-responsive" src="assets/imgs/page/homepage1/img-2.png" alt="Agon" /></div>
                            <div className="col-lg-6 col-sm-12 block-we-do"><span className="tag-1">What We Do, What You Get</span>
                            <h3 className="text-heading-1 mt-30">We believe that our works can contribute to a better world.</h3>
                            <p className="text-body-lead-large color-gray-600 mt-30">Necessary to deliver white glove, fully managed campaigns that surpass industry benchmarks.Take your career to next level.</p>
                            <div className="line-bd-green mt-50"></div>
                            <div className="row">
                                <div className="col-lg-6 col-sm-6 col-12 mt-50">
                                <h4 className="text-heading-6 icon-leaf">Boost your sale</h4>
                                <p className="text-body-excerpt color-gray-600 mt-15">The latest design trends meet hand-crafted templates.</p>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12 mt-50">
                                <h4 className="text-heading-6 icon-leaf">Smart Installation Tools</h4>
                                <p className="text-body-excerpt color-gray-600 mt-15">The latest design trends meet hand-crafted templates.</p>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12 mt-50">
                                <h4 className="text-heading-6 icon-leaf">Introducing New Features</h4>
                                <p className="text-body-excerpt color-gray-600 mt-15">The latest design trends meet hand-crafted templates.</p>
                                </div>
                                <div className="col-lg-6 col-sm-6 col-12 mt-50">
                                <h4 className="text-heading-6 icon-leaf">Dynamic Boosting</h4>
                                <p className="text-body-excerpt color-gray-600 mt-15">The latest design trends meet hand-crafted templates.</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <section className="section-box">
                        <div className="container mt-120">
                        <div className="bg-2 bdrd-58 pattern-white pb-60">
                            <div className="row">
                            <div className="col-lg-2 col-sm-1 col-12"></div>
                            <div className="col-lg-8 col-sm-10 col-12 text-center mt-70">
                                <h2 className="text-heading-1 color-gray-900">What We Offer</h2>
                                <p className="text-body-lead-large color-gray-600 mt-20">What makes us different from others? We give holistic solutions with strategy, design &amp; technology.</p>
                            </div>
                            <div className="col-lg-2 col-sm-1 col-12"></div>
                            </div>
                            <div className="container mt-70">
                            <div className="box-swiper">
                                <div className="swiper-container swiper-group-3">
                                <div className="swiper-wrapper pb-70 pt-5">
                                    <div className="swiper-slide">
                                    <div className="card-grid-style-2 hover-up">
                                        <div className="grid-2-img"><img src="assets/imgs/page/homepage1/market.svg" alt="Agon" /></div>
                                        <h3 className="text-heading-5 mt-20">Market research</h3>
                                        <p className="text-body-text color-gray-600 mt-20">One make creepeth, man bearing theira firmament.</p>
                                    </div>
                                    </div>
                                    <div className="swiper-slide">
                                    <div className="card-grid-style-2 hover-up">
                                        <div className="grid-2-img"><img src="assets/imgs/page/homepage1/consulting.svg" alt="Agon" /></div>
                                        <h3 className="text-heading-5 mt-20">Strategic Consulting</h3>
                                        <p className="text-body-text color-gray-600 mt-20">One make creepeth, man bearing theira firmament.</p>
                                    </div>
                                    </div>
                                    <div className="swiper-slide">
                                    <div className="card-grid-style-2 hover-up">
                                        <div className="grid-2-img"><img src="assets/imgs/page/homepage1/cognity.svg" alt="Agon" /></div>
                                        <h3 className="text-heading-5 mt-20">Cognitive Solution</h3>
                                        <p className="text-body-text color-gray-600 mt-20">One make creepeth, man bearing theira firmament.</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="swiper-pagination swiper-pagination3"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <section className="section-box">
                        <div className="container mt-110">
                        <div className="row">
                            <div className="col-lg-9 col-sm-8">
                            <h3 className="text-heading-1 mb-10">Our Happy Customers</h3>
                            <p className="text-body-lead-large color-gray-600">Know about our clients, we are a woldwide corporate brand</p>
                            </div>
                        </div>
                        </div>
                        <div className="container mt-80">
                        <div className="box-swiper">
                            <div className="swiper-container swiper-group-4">
                            <div className="swiper-wrapper pb-70 pt-5">
                                <div className="swiper-slide active">
                                <div className="card-grid-style-3 hover-up">
                                    <div className="grid-3-img"><img src="assets/imgs/page/homepage1/user-1.png" alt="Agon" /></div>
                                    <h3 className="text-heading-6 mb-5 mt-20">Wade Warren</h3><span className="text-body-small d-block">Louis Vuitton</span>
                                    <p className="text-body-text text-desc color-gray-500 mt-20">Even factoring differences in body weight between children and adults into account.</p>
                                </div>
                                </div>
                                <div className="swiper-slide">
                                <div className="card-grid-style-3 bd-bg-6 hover-up">
                                    <div className="grid-3-img"><img src="assets/imgs/page/homepage1/user-2.png" alt="Agon" /></div>
                                    <h3 className="text-heading-5 mt-20">Brooklyn Simmons</h3><span className="text-body-small d-block">Nintendo</span>
                                    <p className="text-body-text text-desc color-gray-500 mt-20">So yes, the alcohol (ethanol) in hand sanitizers can be absorbed through the skin, but no, it would not cause intoxication.</p>
                                </div>
                                </div>
                                <div className="swiper-slide">
                                <div className="card-grid-style-3 bd-bg-10 hover-up">
                                    <div className="grid-3-img"><img src="assets/imgs/page/homepage1/user-3.png" alt="Agon" /></div>
                                    <h3 className="text-heading-6 mb-5 mt-20">Jenny Wilson</h3><span className="text-body-small d-block">Starbucks</span>
                                    <p className="text-body-text text-desc color-gray-500 mt-20">Their blood alcohol levels rose to 0.007 to 0.02 o/oo (parts per thousand), or 0.7 to 2.0 mg/L.</p>
                                </div>
                                </div>
                                <div className="swiper-slide">
                                <div className="card-grid-style-3 bd-bg-9 hover-up">
                                    <div className="grid-3-img"><img src="assets/imgs/page/homepage1/user-4.png" alt="Agon" /></div>
                                    <h3 className="text-heading-5 mt-20">Albert Flores</h3><span className="text-body-small d-block">Bank of America</span>
                                    <p className="text-body-text text-desc color-gray-500 mt-20">So yes, the alcohol (ethanol) in hand sanitizers can be absorbed through the skin, but no, it would not cause intoxication.</p>
                                </div>
                                </div>
                            </div>
                            <div className="swiper-pagination swiper-pagination3"></div>
                            </div>
                            <div className="swiper-button-next swiper-button-next-4"></div>
                            <div className="swiper-button-prev swiper-button-prev-4"></div>
                        </div>
                        </div>
                    </section>
                    <section className="section-box">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                            <h3 className="text-heading-1 mb-10">Latest News</h3>
                            <p className="text-body-lead-large color-gray-600">From Our blog and Event fanpage</p>
                            </div>
                            <div className="col-lg-4 text-lg-end text-start pt-30"><a className="btn btn-black icon-arrow-right-white" href="blog-1.html">View More</a></div>
                        </div>
                        </div>
                        <div className="container mt-90">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12 pr-30">
                            <div className="card-grid-style-4"><span className="tag-dot">Company</span><a className="text-heading-4" href="blog-single.html">We can blend colors multiple ways, the most common</a>
                                <div className="grid-4-img"><a href="blog-single.html"><img src="assets/imgs/page/homepage1/img-news-1.png" alt="Agon" /></a></div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 pr-30">
                            <div className="card-grid-style-4"><span className="tag-dot">Marketing Event</span><a className="text-heading-4" href="blog-single.html">How To Blow Through Capital At An Incredible Rate</a>
                                <div className="grid-4-img"><a href="blog-single.html"><img src="assets/imgs/page/homepage1/img-news-2.png" alt="Agon" /></a></div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-sm-12 pr-30">
                            <div className="card-grid-style-4"><span className="tag-dot">Customer Services</span><a className="text-heading-4" href="blog-single.html">Design Studios That Everyone Should Know About?</a>
                                <div className="grid-4-img color-bg-4"><a href="blog-single.html"><img src="assets/imgs/page/homepage1/img-news-3.png" alt="Agon" /></a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <section className="section-box section-green mt-100">
                        <div className="container mt-70">
                        <h3 className="text-heading-1 color-white text-center mb-10">Choose The Best Plan<br className="d-lg-block d-none" />That&rsquo;s For You</h3>
                        </div>
                        <div className="container mt-70">
                        <div className="text-center mt-10"><span className="text-lg text-billed">Billed Monthly</span><label className="switch ml-20 mr-20"><input id="cb_billed_type" type="checkbox" name="billed_type" onchange="checkBilled()" /><span className="slider round"></span></label><span className="text-lg text-billed">Bill Annually</span></div>
                        <div className="block-pricing mt-70">
                            <div className="row">
                            <div className="col-xl-10 col-lg-12 offset-xl-1">
                                <div className="row">
                                <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                                    <div className="box-pricing-item">
                                    <div className="box-info-price"><span className="text-heading-3 for-month display-month">$35</span><span className="text-heading-3 for-year">$420</span><span className="text-month for-month text-body-small color-gray-400">/month</span><span className="text-month for-year text-body-small color-gray-400">/year</span></div>
                                    <div className="line-bd-bottom">
                                        <h4 className="text-heading-5 mb-15">Standard</h4>
                                        <p className="text-body-small color-gray-400">All the basics for businesses that are just getting started.</p>
                                    </div>
                                    <ul className="list-package-feature">
                                        <li>Unlimited updates</li>
                                        <li>Custom permissions</li>
                                        <li className="uncheck">Custom designs &amp; features</li>
                                        <li className="uncheck">Custom hashtags</li>
                                    </ul>
                                    <div><a className="btn btn-black-border text-body-lead icon-arrow-right" href="#">Get Started</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow animate__animated animate__fadeIn" data-wow-delay=".2s">
                                    <div className="box-pricing-item">
                                    <div className="box-info-price"><span className="text-heading-3 for-month display-month">$89</span><span className="text-heading-3 for-year">$1068</span><span className="text-month for-month text-body-small color-gray-400">/month</span><span className="text-month for-year text-body-small color-gray-400">/year</span></div>
                                    <div className="line-bd-bottom">
                                        <h4 className="text-heading-5 mb-15">Essentials</h4>
                                        <p className="text-body-small color-gray-400">All the basics for businesses that are just getting started.</p>
                                    </div>
                                    <ul className="list-package-feature">
                                        <li>Unlimited updates</li>
                                        <li>Custom permissions</li>
                                        <li>Custom instructors</li>
                                        <li className="uncheck">Optimize hashtags</li>
                                    </ul>
                                    <div><a className="btn btn-black-border text-body-lead icon-arrow-right" href="#">Get Started</a></div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 wow animate__animated animate__fadeIn" data-wow-delay=".4s">
                                    <div className="box-pricing-item">
                                    <div className="box-info-price"><span className="text-heading-3 for-month display-month">$125</span><span className="text-heading-3 for-year">$1500</span><span className="text-month for-month text-body-small color-gray-400">/month</span><span className="text-month for-year text-body-small color-gray-400">/year</span></div>
                                    <div className="line-bd-bottom">
                                        <h4 className="text-heading-5 mb-15">Premium</h4>
                                        <p className="text-body-small color-gray-400">Avvanced features for pros who need more customization.</p>
                                    </div>
                                    <ul className="list-package-feature">
                                        <li>Unlimited updates</li>
                                        <li>Custom designs &amp; features</li>
                                        <li>Custom permissions</li>
                                        <li>Optimize hashtags</li>
                                        <li>Custom instructors</li>
                                    </ul>
                                    <div><a className="btn btn-black-border text-body-lead icon-arrow-right" href="#">Get Started</a></div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <section className="section-box overflow-visible mb-100">
                        <div className="container mt-100">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                            <div className="bg-2 box-newsletter position-relative">
                                <div className="row">
                                <div className="col-lg-5 col-md-7"><span className="text-body-capitalized color-gray-500 text-uppercase">newsletter</span>
                                    <h4 className="text-heading-2 mb-10 mt-10">Subscribe our newsletter</h4>
                                    <p className="text-body-text color-gray-500">By clicking the button, you are agreeing with our</p><a href="page-terms.html">Term &amp; Conditions</a>
                                    <div className="box-form-newsletter mt-30">
                                    <form className="form-newsletter"><input className="input-newsletter" value="" placeholder="Enter you mail .." /><button className="btn btn-send"></button></form>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-5 mt-30 mt-lg-0 mt-md-30 mt-sm-30 position-relative text-end">
                                    <div className="block-chart shape-1"><img src="assets/imgs/template/chart.png" alt="Agon" /></div><img className="img-responsive img-newsletter" src="assets/imgs/template/img-newsletter.png" alt="Agon" />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
                </main>
            <Footer />
        </div>
    );
};

export default Home;