import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Services = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('http://127.0.0.1:1234/api/services/')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    
    return (
        <div>
            
        {console.log('render')}
            <h1 className='text-center'>Services</h1>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className='container'>
                    <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Cover</th>
                            <th>Name</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(service => (
                            <tr key={service.id}>
                                <td>{service.id}</td>
                                <td>
                                    <img src={service.cover} alt={service.name} height={200} />
                                </td>
                                <td>{service.name}</td>
                                <td>{service.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                </div>
            )}
        </div>
    );
};

export default Services;
